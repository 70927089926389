@import url('https://fonts.googleapis.com/css2?family=Martian+Mono&display=swap');

html {
    --black: #000000;
    --white: #ffffff;
    --darker: #000000;
    --dark: #a3afbf;
    --medium: #dfe7ef;
    --light: #cad4e1;
    --lighter: #f5f8fc;
    --lightest: var(--white);
    --primary: #5865F2;
    --primary-light: #ddd9ff;
    --yellow: #fdcb6e;
    --orange: #e17055;
    --teal: #00cec9;
    --bg: var(--black);
    --color: var(--lightest);
    --surface: var(--darker);
}

html {
    font-size: 62.5%;
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

html,
body {
    width: 100%;
    height: 100%;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    background: var(--bg);
    color: var(--color);
    font-size: 1.6rem;
    font-family: 'Martian Mono', monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
    font-family: 'Martian Mono', monospace;
}

.danger {
    color: #ff0000;
    display: inline-block;
    transition: all 120ms ease-out 0s;
}


.link {
    color: var(--primary);
    text-decoration: none;
    transition: all 120ms ease-out 0s;
    display: inline-block;
}

button,
textarea,
input,
select {
    font-family: inherit;
    color: inherit;
}

button:active,
button:focus,
textarea:active,
textarea:focus,
input:active,
input:focus,
select:active,
select:focus {
    outline: 0;
}

button,
select {
    cursor: pointer;
}

.l-wrapper {
    width: 100%;
    max-width: 960px;
    margin: auto;
    padding: 1.6rem 1.6rem 3.2rem;
}

.l-grid {
    display: grid;
    grid-column-gap: 1.6rem;
    grid-row-gap: 1.6rem;
    position: relative;
}

.c-header {
    align-items: center;
    padding: 1.6rem 0;
    display: flex;
    justify-content: center;
    margin-bottom: 2.4rem;
    position: relative;
}

.c-header:before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: 0.2rem;
    background: var(--primary);
}

.c-card {
    border-radius: 0.8rem;
    background: var(--darker);
    width: 100%;
    margin-bottom: 1.6rem;
    outline: 2px solid var(--primary);
}

.c-card__body,
.c-card__header {
    padding: 0.4rem 2.4rem;
}

.c-card__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 0;
}

.c-logo {
    display: inline-block;
    /* width: 100%; */
    max-width: 17.6rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    object-fit: contain;
    border-radius: 14px;
}

.c-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.c-list__item {
    padding: 1.6rem 0;
}

.c-list__item .c-flag {
    margin: auto;
}


.c-list__grid {
    display: grid;
    grid-column-gap: 2.4rem;
    grid-template-columns: 4.8rem 3fr 0.7fr 0.7fr 0.7fr;
}


.c-media {
    display: inline-flex;
    align-items: center;
}

.c-media__content {
    padding-left: 1.6rem;
}

.c-media__title {
    margin-bottom: 0.4rem;
    white-space: pre-line;
    word-break: break-word;
    word-break: break-word;
    overflow-wrap: break-word;
    -ms-word-break: break-word;
}

.c-avatar {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 4.8rem;
    height: 4.8rem;
    /* box-shadow: inset 0px 0px 0px 1px currentColor; */
    outline: 2px solid currentColor;
    border-radius: 5px;
    background: var(--lightest);
    color: var(--dark);
    object-fit: cover;
}

.c-location-image {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    /* height: 4.8rem; */
    border-radius: 5px;
    color: var(--dark);
    object-fit: cover;
}

@media screen and (max-width: 700px) {
    .c-avatar {
        width: 3.2rem;
        height: 3.2rem;
    }
}

.c-avatar--lg {
    width: 9.6rem;
    height: 9.6rem;
}

.c-button {
    display: inline-block;
    background: var(--dark);
    border: 0;
    border-radius: 0.4rem;
    padding: 1.2rem 2rem;
    transition: all 120ms ease-out 0s;
}

.c-button--block {
    display: block;
    width: 100%;
}

.c-select {
    background: var(--darker);
    padding: 1.2rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: 1.4rem;
    border-color: rgba(255, 255, 255, 0.2);
    transition: all 120ms ease-out 0s;
    border-radius: 5px;
    border-width: 0px;
    outline: 2px solid hsla(0, 0%, 100%, 0.12);
}

.c-select:hover {
    background: var(--darkest);
}

.c-select:active,
.c-select:focus {
    outline: 2px solid hsla(0, 0%, 100%, 0.12);
}

.c-flag {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 3.2rem;
    height: 3.2rem;
    background: var(--lightest);
    color: var(--dark);
    border-radius: 0.4rem;
}

@media screen and (max-width: 700px) {
    .c-flag {
        width: 2.4rem;
        height: 2.4rem;
    }
}

.c-button--light {
    background: var(--lightest);
}

.c-button--primary {
    cursor: default;
    background: var(--primary);
    font-size: larger !important;
    font-weight: bolder !important;
}

.c-button--dark {
    background: var(--darkest);
}

.c-button--transparent {
    background: transparent;
}

.c-button--medium {
    background: var(--medium);
}

.c-button--yellow {
    background: var(--yellow);
}

.c-button--orange {
    background: var(--orange);
}

.c-button--teal {
    background: var(--teal);
}

.c-button--light-gradient {
    background: linear-gradient(to top, var(--light), var(--lightest));
}

.u-text--left {
    text-align: left;
}

.u-text--center {
    text-align: center;
}

.u-text--right {
    text-align: right;
}

.u-bg--light {
    background: var(--lightest) !important;
}

.u-text--light {
    color: var(--lightest) !important;
}

.u-bg--primary {
    background: var(--primary) !important;
}

.u-text--primary {
    color: var(--primary) !important;
}

.u-bg--dark {
    background: var(--darkest) !important;
}

.u-text--dark {
    color: var(--darkest) !important;
}

.u-bg--transparent {
    background: transparent !important;
}

.u-text--transparent {
    color: transparent !important;
}

.u-text--medium {
    color: #ffffff !important;
    font-size: 1.6rem !important;
    font-weight: bolder !important;
}

.u-bg--yellow {
    background: gold !important;
}

.u-text--yellow {
    color: gold !important;
}

.u-bg--teal {
    background: silver !important;
}

.u-text--teal {
    color: silver !important;
}

.u-bg--orange {
    background: #cd7f32 !important;
}

.u-text--orange {
    color: #cd7f32 !important;
}

.u-bg--light-gradient {
    background: linear-gradient(to top, var(--light), var(--lightest)) !important;
}

.u-text--light-gradient {
    color: linear-gradient(to top, var(--light), var(--lightest)) !important;
}

.u-display--flex {
    display: flex;
}

.u-align--center {
    align-items: center;
}

.u-justify--center {
    justify-content: center;
}

.u-align--flex-end {
    align-items: flex-end;
}

.u-justify--flex-end {
    justify-content: flex-end;
}

.u-align--flex-start {
    align-items: flex-start;
}

.u-justify--flex-start {
    justify-content: flex-start;
}

.u-align--space-between {
    align-items: space-between;
}

.u-justify--space-between {
    justify-content: space-between;
}

.u-pl--2 {
    padding-left: 0.2rem;
}

.u-ml--2 {
    margin-left: 0.2rem;
}

.u-pr--2 {
    padding-right: 0.2rem;
}

.u-mr--2 {
    margin-right: 0.2rem;
}

.u-pb--2 {
    padding-bottom: 0.2rem;
}

.u-mb--2 {
    margin-bottom: 0.2rem;
}

.u-pt--2 {
    padding-top: 0.2rem;
}

.u-mt--2 {
    margin-top: 0.2rem;
}

.u-pl--4 {
    padding-left: 0.4rem;
}

.u-ml--4 {
    margin-left: 0.4rem;
}

.u-pr--4 {
    padding-right: 0.4rem;
}

.u-mr--4 {
    margin-right: 0.4rem;
}

.u-pb--4 {
    padding-bottom: 0.4rem;
}

.u-mb--4 {
    margin-bottom: 0.4rem;
}

.u-pt--4 {
    padding-top: 0.4rem;
}

.u-mt--4 {
    margin-top: 0.4rem;
}

.u-pl--8 {
    padding-left: 0.8rem;
}

.u-ml--8 {
    margin-left: 0.8rem;
}

.u-pr--8 {
    padding-right: 0.8rem;
}

.u-mr--8 {
    margin-right: 0.8rem;
}

.u-pb--8 {
    padding-bottom: 0.8rem;
}

.u-mb--8 {
    margin-bottom: 0.8rem;
}

.u-pt--8 {
    padding-top: 0.8rem;
}

.u-mt--8 {
    margin-top: 0.8rem;
}

.u-pl--16 {
    padding-left: 1.6rem;
}

.u-ml--16 {
    margin-left: 1.6rem;
}

.u-pr--16 {
    padding-right: 1.6rem;
}

.u-mr--16 {
    margin-right: 1.6rem;
}

.u-pb--16 {
    padding-bottom: 1.6rem;
}

.u-mb--16 {
    margin-bottom: 1.6rem;
}

.u-pt--16 {
    padding-top: 1.6rem;
}

.u-mt--16 {
    margin-top: 1.6rem;
}

.u-pl--24 {
    padding-left: 2.4rem;
}

.u-ml--24 {
    margin-left: 2.4rem;
}

.u-pr--24 {
    padding-right: 2.4rem;
}

.u-mr--24 {
    margin-right: 2.4rem;
}

.u-pb--24 {
    padding-bottom: 2.4rem;
}

.u-mb--24 {
    margin-bottom: 2.4rem;
}

.u-pt--24 {
    padding-top: 2.4rem;
}

.u-mt--24 {
    margin-top: 2.4rem;
}

.u-pl--32 {
    padding-left: 3.2rem;
}

.u-ml--32 {
    margin-left: 3.2rem;
}

.u-pr--32 {
    padding-right: 3.2rem;
}

.u-mr--32 {
    margin-right: 3.2rem;
}

.u-pb--32 {
    padding-bottom: 3.2rem;
}

.u-mb--32 {
    margin-bottom: 3.2rem;
}

.u-pt--32 {
    padding-top: 3.2rem;
}

.u-mt--32 {
    margin-top: 3.2rem;
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-thumb {
    background-color: #5865f2;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #4857ff;
}

@media screen and (max-width: 700px) {

    .c-card__body,
    .c-card__header {
        padding: 1rem;
    }
}

@media screen and (max-width: 700px) {
    .c-card__header {
        flex-direction: column;
    }
}

@media screen and (max-width: 700px) {
    .c-place {
        transform: translateY(4px);
    }
}

@media screen and (max-width: 700px) {
    .c-list__item .c-flag {
        position: relative;
        bottom: 12.5%;
    }
}

@media screen and (max-width: 700px) {
    .c-list__grid {
        grid-column-gap: 0.8rem;
        grid-template-columns: 4rem 3fr 1fr 1fr 1fr;
    }
}

@media screen and (max-width: 700px) {
    .c-media__content {
        padding-left: 0.8rem;
    }
}

@media screen and (max-width: 700px) {
    .c-media__title {
        font-size: 1.2rem;
    }

    .u-text--medium {
        color: #ffffff !important;
        font-size: 1.2rem !important;
        font-weight: bolder !important;
    }

    .u-text--right {
        font-size: 1.2rem;
    }
}